/**
 * Description of Cookiedisclaimer.
 * Class properties and decorators are supported.
 *
 * @module Cookiedisclaimer
 * @version v0.0.0
 *
 * @author your_name
 */

// Imports
import $ from 'jquery';
import Component from '@veams/component';
require('cookieconsent');

class Cookiedisclaimer extends Component {
	/**
	 * Class Properties
	 */
	$el = $(this.el);

	/**
	 * Constructor for our class
	 *
	 * @see module.js
	 *
	 * @param {Object} obj - Object which is passed to our class
	 * @param {Object} obj.el - element which will be saved in this.el
	 * @param {Object} obj.options - options which will be passed in as JSON object
	 */
	constructor(obj) {
		let options = {
			selectors: {},
			classes: {}
		};

		super(obj, options);
	}

	didMount() {
		console.log('Component Cookiedisclaimer mounted!');
    let component = this;
		window.addEventListener("load", function() {
			window.cookieconsent.initialise({
				"palette": {
					"popup": {
						"background": "#c1735f",
						"text": "#ffffff"
					},
					"button": {
						"background": "transparent",
						"text": "#ffffff",
						"border": "#ffffff"
					}
				},
				"content": {
					"message": component.options.message,
					"dismiss": component.options.dismiss,
					"link": component.options.link,
          "href": component.options.url
				}
			})
		});
	}

	/**
	 * Render class
	 */
	render() {
		return this;
	}
}

export default Cookiedisclaimer;
