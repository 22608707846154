/**
 * Description of AdhocArchive.
 * Class properties and decorators are supported.
 *
 * @module AdhocArchive
 * @version v0.0.0
 *
 * @author your_name
 */

// Imports
import $ from 'jquery';

import Component from '@veams/component';

class adhocArchive extends Component {
	/**
	 * Class Properties
	 */
	$el = $(this.el);

	/**
	 * Event & subscribe handling
	 */

	// Local Handlers
	get events() {
		return {
			//"click {{this.options.selectors.btnOpenLevel1}}": "onBtnClick"
		};
	}

	// Global Handlers
	get subscribe() {
		return {};
	}


	/**
	 * Constructor for our class
	 *
	 * @see module.js
	 *
	 * @param {Object} obj - Object which is passed to our class
	 * @param {Object} obj.el - element which will be saved in this.el
	 * @param {Object} obj.options - options which will be passed in as JSON object
	 */
	constructor(obj) {
		let options = {
			selectors: {
				//navMainLink: ".nav-main__link",
			},
			classes: {
				//isActive: "is-active",
			}
		};

		super(obj, options);
	}


	/**
	 * Lifecycle Hooks
	 */

	didMount() {
		console.log('Component AdhocArchive mounted!');
	}

	/**
	 * Render class
	 */
	render() {
		return this;
	}

	onResize = (evt, target) => {
	};
}

export default adhocArchive;
