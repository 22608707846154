/**
 * Description of Cta.
 * Class properties and decorators are supported.
 *
 * @module Cta
 * @version v0.0.0
 *
 * @author your_name
 */

// Imports
import $ from 'jquery';
import Component from '@veams/component';

class Cta extends Component {
	/**
	 * Class Properties
	 */
	$el = $(this.el);

	/**
	 * Event & subscribe handling
	 */

	// Local Handlers
	get events() {
		return {
			'click': 'handleClick'
		};
	}

	toggleCta = () => {
		if (this.options.isNavInteractive) {
			this.$el.fadeToggle(0);
		}
	}

	showCta = () => {
		this.$el.fadeIn(0);
		this.isActive = true;
	};

	hideCta = () => {
		this.$el.fadeOut(0);
		this.isActive = false;
	};

	handleClick = (evt, target) => {
		//console.log(`You clicked on: `, target);
		//console.log('You got the following event: ', evt);
		if (this.options.triggerEvent.length >= 1) {
			Veams.Vent.trigger(this.options.triggerEvent);
		}
	};

	// Global Handlers
	get subscribe() {
		return {
			'{{context.EVENTS.navMain.toggle}}': 'toggleCta'
		};
	}

	/**
	 * Constructor for our class
	 *
	 * @see module.js
	 *
	 * @param {Object} obj - Object which is passed to our class
	 * @param {Object} obj.el - element which will be saved in this.el
	 * @param {Object} obj.options - options which will be passed in as JSON object
	 */
	constructor(obj) {
		let options = {
			selectors: {},
			classes: {

      }
		};

		super(obj, options);
	}

	didMount() {
		console.log('Component Cta mounted!');
	}

	/**
	 * Render class
	 */
	render() {
		return this;
	}
}

export default Cta;
