/**
 * Description of Youtube.
 * Class properties and decorators are supported.
 *
 * @module Youtube
 * @version v0.0.0
 *
 * @author Noémie Viatte
 */

// Imports
import $ from 'jquery';
import Component from '@veams/component';

class Youtube extends Component {
	/**
	 * Class Properties
	 */
	$el = $(this.el);

	/**
	 * Event & subscribe handling
	 */

	// Local Handlers
	get events() {
		return {
			'click {{this.options.playBtnSelector}}': 'playVideo'
		};
	}

	handleClick = (evt, target) => {
		console.log(`You clicked on: `, target);
		console.log('You got the following event: ', evt);
	};

	/**
	 * Subscribe handling
	 */
	get subscribe() {
		return {
			'{{Veams.EVENTS.youtubeApiReady}}': 'loadVideo'
		};
	}

	/**
	 * Constructor for our class
	 *
	 * @see module.js
	 *
	 * @param {Object} obj - Object which is passed to our class
	 * @param {Object} obj.el - element which will be saved in this.el
	 * @param {Object} obj.options - options which will be passed in as JSON object
	 */
	constructor(obj) {
		let options = {
			selectors: {},
			classes: {}
		};

		super(obj, options);
	}


	didMount() {
		console.log('Component Youtube mounted!');
		let component = this;
		this.$playBtn = this.$el.find(this.options.playBtnSelector);
    this.loadYoutubeApi();

		window.onYouTubeIframeAPIReady = function() {
			let component = this;
			Veams.Vent.trigger(Veams.EVENTS.youtubeApiReady, {});
		}
	}

	loadYoutubeApi() {
		// Check if API already loaded
		if ((typeof(YT) == 'undefined' || typeof(YT.Player) == 'undefined') && window.youtubeApiLoading !== true) {
			window.youtubeApiLoading = true;
			var tag = document.createElement('script');
			tag.src = "//www.youtube.com/iframe_api";
			var firstScriptTag = document.getElementsByTagName('script')[0];
			firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
		}
	}

	loadVideo() {
		let component = this;
		this.player = new YT.Player(this.$el.find(".youtube__player")[0], {
			height: '360',
			width: '640',
			videoId: component.options.youtubeId,
			playerVars: {
				autoplay: 0,
				controls: 1,
				modestbranding: 1,
				wmode: "opaque",
				rel: 0,
				showinfo: 0,
        autohide: 1,
        playsinline: 1
			},
			events: {
				//'onReady': this.onPlayerReady,
				//'onStateChange': this.onPlayerStateChange(component)
			}
		});
	}

	onPlayerStateChange(event) {
		/*console.log(event);
		if (event.data == YT.PlayerState.PLAYING) {
		  event.$el.addClass("playing");
		} else {
		  event.$el.removeClass("playing");
		}*/
	}

	playVideo() {
    if(this.options.openInOverlay) {
      Veams.Vent.trigger(Veams.EVENTS.youtube.play, {
        el: this.$el.html()
      });
    } else {
      this.player.playVideo();
      this.$el.addClass("playing");
    }
	}

	stopVideo() {
		this.player.stopVideo();
	}

	/**
	 * Render class
	 */
	render() {
		return this;
	}
}

export default Youtube;
