/**
 * Description of TList.
 * Class properties and decorators are supported.
 *
 * @module Search
 * @version v0.0.0
 *
 * @author your_name
 */

// Imports
import $ from 'jquery';
import Component from '@veams/component';

class TList extends Component {
	/**
	 * Class Properties
	 */
	$el = $(this.el);

	hiddenBtnLoad = () => {
		console.log(this.options.selectors.loadBtnWrapper);
		this.$el.find(this.options.selectors.loadBtnWrapper).addClass(this.options.classes.hidden);
	}

	displayTeaser = (nb) => {
		let $teaserItems = this.$el.find(this.options.selectors.itemHidden);
		if (nb == -1) {
			$teaserItems.removeClass(this.options.classes.hidden);
			this.hiddenBtnLoad();
		} else {
			for (let i = 0; i < nb; i++) {
				$($teaserItems[i]).removeClass(this.options.classes.hidden);
				if (this.$el.find(this.options.selectors.itemHidden).length == 0) {
					this.hiddenBtnLoad();
				}
			}
		}

	}

	displayNbDefaultTeasers = () => {
		let nbDefautTeaser = this.options.nbTeaserOnStart;
		this.displayTeaser(nbDefautTeaser);
	}

	onLoadBtnClick = () => {
		this.displayTeaser(this.options.nbTeaserOnLoad);
	}

	/**
	 * Event & subscribe handling
	 */

	// Local Handlers
	get events() {
		return {
			'click {{this.options.selectors.loadBnt}}': 'onLoadBtnClick'
		};
	}


	// Global Handlers
	get subscribe() {
		return {};
	}

	/**
	 * Constructor for our class
	 *
	 * @see module.js
	 *
	 * @param {Object} obj - Object which is passed to our class
	 * @param {Object} obj.el - element which will be saved in this.el
	 * @param {Object} obj.options - options which will be passed in as JSON object
	 */
	constructor(obj) {
		let options = {
			selectors: {
				loadBtnWrapper: ".t-list__btn-wrapper",
				loadBnt: ".c-cta--load",
				itemHidden: ".t-list__item.is-hidden"
			},
			classes: {
				hidden: "is-hidden"
			}
		};

		super(obj, options);
	}

	didMount() {
		console.log('Component TList mounted!');
		this.displayNbDefaultTeasers();
	}

	/**
	 * Render class
	 */
	render() {
		return this;
	}
}

export default TList;
