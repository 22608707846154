/**
 * Description of PhoenixPanel.
 * Class properties and decorators are supported.
 *
 * @module PhoenixPanel
 * @version v0.0.0
 *
 * @author your_name
 */

// Imports
import Component from '@veams/component';

class PhoenixPanel extends Component {
	/**
	 * Class Properties
	 */
	get isCountryPassed() {
		return this._isCountryPassed;
	}

	set isCountryPassed(passed) {
		this._isCountryPassed = passed;
	}

	get isResidencePassed() {
		return this._isResidencePassed;
	}

	set isResidencePassed(passed) {
		this._isResidencePassed = passed;
	}

	get isConditionPassed() {
		return this._isConditionPassed;
	}

	set isConditionPassed(passed) {
		this._isConditionPassed = passed;
	}

	phoenixLs = "PHOENIX_LS-V1";
	phoenixAppContainer = this.el.querySelector(this.options.selectors.phoenixApp);
	phoenixStepEls = this.el.querySelectorAll(this.options.selectors.steps)

	/**
	 * Event & subscribe handling
	 */

	// Local Handlers
	get events() {
		return {};
	}

	// Global Handlers
	get subscribe() {
		return {
			'{{Veams.EVENTS.form.phoenix}}': 'updateForm'
		};
	}

	/**
	 * Constructor for our class
	 *
	 * @see module.js
	 *
	 * @param {Object} obj - Object which is passed to our class
	 * @param {Object} obj.el - element which will be saved in this.el
	 * @param {Object} obj.options - options which will be passed in as JSON object
	 */
	constructor(obj) {
		let options = {
			selectors: {
				phoenixApp: '.phoenix-app',
				steps: '.phoenix-step'
			},
			classes: {
				isActive: 'active'
			},
			lsState: {
				valid: "valid",
				pending: "pending",
				invalid: "invalid"
			}
		};

		super(obj, options);
	}

	didMount() {
		let comp = this;
		let ls = JSON.parse(localStorage.getItem(this.phoenixLs));
console.log("ls", ls);
		this.isCountryPassed = false;
		this.isResidencePassed = false;
		this.isConditionPassed = false;

		if (ls !== null && ls.valid === this.options.lsState.invalid) {
				comp.updateView(ls.stepError);

		} else if (ls !== null && ls.valid === this.options.lsState.valid) {
				comp.updateView('.phoenix-4');

		} else if (ls === null) {
			comp.updateView('.phoenix-1');
		}
	}

	/**
	 * Render class
	 */
	render() {
		return this;
	}

	updateForm = (step) => {
		let comp = this;
		console.log("Step", step);
		if (this.checkIfSwitzerland(step.controlJsItem) && !this.isCountryPassed) {
			this.isCountryPassed = true;
			comp.updateView(step.nextStepSelector);
		} else if (this.checkIfResidence(step.controlJsItem) && !this.isResidencePassed) {
			this.isResidencePassed = true;
				comp.updateView(step.nextStepSelector);
		} else if (this.checkIfCondition(step.controlJsItem) && !this.isConditionPassed) {
			this.isConditionPassed = true;
				comp.updateView(step.nextStepSelector);
			console.log("Something",);
			localStorage.setItem(this.phoenixLs, JSON.stringify({
				valid: this.options.lsState.valid,
				stepError: null
			}));
		} else {
				comp.updateView(step.stepErrorSelector);
			localStorage.setItem(this.phoenixLs, JSON.stringify({
				valid: this.options.lsState.invalid,
				stepError: step.stepErrorSelector
			}));
		}
	};

	checkIfSwitzerland = (controller) => {
		let control = this.el.querySelector('[data-js-item="' + controller + '"]') || false;
		console.log("Input select", control.value);
		if (control) {
			return control.value === "CH";
		} else {
			return false;
		}
	};
	checkIfResidence = (controller) => {
		let control = this.el.querySelector('[data-js-item="' + controller + '"]') || false;
		if (control) {
			return control.checked;
		} else {
			return false;
		}
	};
	checkIfCondition = (controller) => {
		let control = this.el.querySelector('[data-js-item="' + controller + '"]') || false;
		console.log("Input check", control.checked);
		if (control) {
			return control.checked;
		} else {
			return false;
		}
	};

	getNewHtmlTemplate = (url) => {
		return fetch(url).then(function (response) {
			return response.text();
		}).then(function (text) {
			return text;
		}).catch(function (error) {
			console.log("Error", error);
		});
	};

	updateView = (selector) => {
		let activeItem = this.el.querySelector(selector);
		for (let index = 0; index < this.phoenixStepEls.length; index++) {
			let item = this.phoenixStepEls[index];
			item.classList.remove(this.options.classes.isActive)
		}
console.log("Item selctore", selector);
console.log("Item ", activeItem);
		activeItem.classList.add(this.options.classes.isActive);
	};

}

export default PhoenixPanel;
