/**
 * Description of NavMain.
 * Class properties and decorators are supported.
 *
 * @module NavMain
 * @version v0.0.0
 *
 * @author your_name
 */

// Imports
import $ from 'jquery';

import Component from '@veams/component';

class NavMain extends Component {
	/**
	 * Class Properties
	 */
	$el = $(this.el);

	/**
	 * Event & subscribe handling
	 */

	// Local Handlers
	get events() {
		return {
			"click {{this.options.selectors.btnOpenLevel1}}": "onBtnClick"
		};
	}

	// Global Handlers
	get subscribe() {
		return {
			'{{context.EVENTS.resize}}': 'onResize',
			'{{context.EVENTS.navMain.toggle}}': 'toggleNav'
		};
	}

	get isActive() {
		return this._isActive || false;
	}

	set isActive(active) {
		this._isActive = active;
	}



	/**
	 * Constructor for our class
	 *
	 * @see module.js
	 *
	 * @param {Object} obj - Object which is passed to our class
	 * @param {Object} obj.el - element which will be saved in this.el
	 * @param {Object} obj.options - options which will be passed in as JSON object
	 */
	constructor(obj) {
		let options = {
			selectors: {
				navMainLink: ".nav-main__link",
				btnOpenLevel1: ".nav-main__btn",
				subNavsLink: ".nav-main__subnav-link",
				navMainItem: ".nav-main__list-item",
				subNavItem: ".nav-main__subnav-item"
			},
			classes: {
				isActive: "is-active",
				isCurrent: "is-current",
				isHidden: "is-hidden"
			}
		};

		super(obj, options);
	}


	/**
	 * Lifecycle Hooks
	 */

	didMount() {
		console.log('Component NavMain mounted!');
    let component = this;
		this.$navMainLinks = this.$el.find(this.options.selectors.navMainLink);
		this.$subNavsLinks = this.$el.find(this.options.selectors.subNavsLink);
		this.$navMainItems = this.$el.find(this.options.selectors.navMainItem);
		this.$subNavItems = this.$el.find(this.options.selectors.subNavItem);
		this.$el.find(".is-current").parent("li").addClass(this.options.classes.isActive);
		if (Veams.detections.width >= 1200) {
			this.desktopActiveLinks();
      //FIX UNKNOWN KURSAAL ERROR;
      setTimeout(function(){
        component.desktopActiveLinks();
      },1000);
		}

	}

	/**
	 * Render class
	 */
	render() {
		return this;
	}

	onResize = (evt, target) => {
		if (Veams.detections.width >= 1200) {
			this.desktopActiveLinks();
		}
		console.log(`Browser was resized and caught by NavMain!`);
		this.hideMobileNav();
	};

	toggleNav = () => {
		let component = this;
		if (this.isActive) {
			this.hideMobileNav();
		} else {
			this.showMobileNav();
		}
	};

	desktopActiveLinks = () => {
		let $linkMainCurrent = this.$el.find(".nav-main__link.is-current");
		let $linkLevel1Current = this.$el.find(".nav-main__subnav-link.is-current");
		let $linkLevel2Current = this.$el.find(".nav-main__subnav-link.level-2.is-current");

		if ($linkLevel2Current.length) {
			//SHOW LEVEL2 LINK
			$linkLevel2Current.closest(".nav-main__subnav-list.level-2").prev(".nav-main__subnav-link.is-current").removeClass(this.options.classes.isHidden);
			//SHOW LEVEL3 LINKS
			$linkLevel1Current.next(".nav-main__subnav-list.level-2").find(".nav-main__subnav-link.level-2").removeClass(this.options.classes.isHidden);
		} else {
			if ($linkLevel1Current.length) {
				//SHOW LEVEL1 LINK
				$linkLevel1Current.removeClass(this.options.classes.isHidden);
				//SHOW LEVEL2 LINKS
				let $nextList = $linkLevel1Current.next(".nav-main__subnav-list");
				$nextList.find(".nav-main__subnav-link").removeClass(this.options.classes.isHidden);

			} else {
				$linkMainCurrent.removeClass(this.options.classes.isHidden);
				let $nextList = $linkMainCurrent.next(".nav-main__subnav-list");
				$nextList.find(".nav-main__subnav-link").removeClass(this.options.classes.isHidden)
			}
		}


		//this.$subNavsLinks.addClass("is-hidden");
		/*
		 let $linkLevel2Current = this.$el.find(".nav-main__subnav-link.level-2.is-current");
		 let $linkLevel1Current = this.$el.find(".nav-main__subnav-link.is-current");
		 let $linkMainCurrent = this.$el.find(".nav-main__link.is-current");
		 if($linkLevel2Current.length >= 1) {
		 let $nextList = $linkLevel2Current.next(".nav-main__subnav-list");
		 if(Veams.detections.width >= 1200) {
		 if($nextList.hasClass("level-3")) {
		 $linkLevel1Current.next().find("a.level-2").removeClass("is-hidden");
		 } else {
		 $linkLevel2Current.removeClass(this.options.classes.isHidden);
		 $nextList.find("a").removeClass(this.options.classes.isHidden);        }
		 $linkLevel2Current.parents(".nav-main__subnav-list.level-2").prev(".nav-main__subnav-link.is-current").removeClass(this.options.classes.isHidden);

		 } else {
		 $linkLevel2Current.removeClass(this.options.classes.isHidden);
		 $nextList.find("a").removeClass(this.options.classes.isHidden);
		 $linkLevel2Current.parents(".nav-main__subnav-list.level-2").prev(".nav-main__subnav-link.is-current").removeClass(this.options.classes.isHidden);

		 }
		 } else {
		 if($linkLevel1Current.length >= 1) {
		 let $nextList = $linkLevel1Current.next(".nav-main__subnav-list");
		 console.log("nav2");
		 $linkLevel1Current.removeClass(this.options.classes.isHidden);

		 $nextList.find("a").removeClass(this.options.classes.isHidden)
		 } else {
		 if($linkMainCurrent.length >= 1)
		 console.log("nav1");
		 $linkMainCurrent.removeClass(this.options.classes.isHidden);
		 let $nextList = $linkMainCurrent.next(".nav-main__subnav-list");
		 $nextList.find("a").removeClass(this.options.classes.isHidden)
		 }
		 }
		 */
	}

	closeAllMainLinks = () => {
		this.$navMainLinks.removeClass(this.options.classes.isCurrent);
		this.$navMainItems.removeClass(this.options.classes.isActive);
	}

	closeAllSubNavs = () => {
		this.$subNavsLinks.removeClass(this.options.classes.isCurrent);
		this.$subNavItems.removeClass(this.options.classes.isActive);
	}

	onBtnClick = (e) => {
		let $btn = $(e.target);
		let $link = $btn.next(this.options.classes.navMainLink);
		let $sublink = $btn.next(this.options.classes.subNavItem);
		let $list = $btn.next(this.options.classes.subNavs);
		let $parent = $btn.parent();

		if ($link.hasClass(this.options.classes.isCurrent)) {
			//if current link is already open
			$link.removeClass(this.options.classes.isCurrent);
			$parent.removeClass(this.options.classes.isActive);
		} else {
			if ($parent.hasClass("nav-main__subnav-item")) {
				console.log($parent);
				//if current link is close AND is a sublink
				if ($parent.hasClass("level-2")) {} else {
					this.closeAllSubNavs();
				}

			} else {
				//if current link is close AND is not a sublink
				this.closeAllSubNavs();
				this.closeAllMainLinks();

			}
			$parent.addClass(this.options.classes.isActive);
			$link.addClass(this.options.classes.isCurrent);
		}
	};

	hideMobileNav = () => {
		this.$el.removeClass(this.options.classes.isActive);
		this.isActive = false;
	};

	showMobileNav = () => {
		this.$el.addClass(this.options.classes.isActive);
		this.isActive = true;
	};
}

export default NavMain;
