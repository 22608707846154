/**
 * Description of Search.
 * Class properties and decorators are supported.
 *
 * @module Search
 * @version v0.0.0
 *
 * @author your_name
 */

// Imports
import $ from 'jquery';
import Component from '@veams/component';

class Search extends Component {
	/**
	 * Class Properties
	 */
	$el = $(this.el);
  $bg = this.$el.find(this.options.selectors.bg);

	/**
	 * Event & subscribe handling
	 */

	// Local Handlers
	get events() {
		return {
			'click {{this.options.selectors.closeEl}}': 'closeSearch',
      'click {{this.options.selectors.bg}}': 'onClickBg',
      'click {{this.options.selectors.lens}}': 'onClickLens',
			'keyup {{this.options.selectors.controlEl}}': 'onKeyUp'

		};
	}



	openSearch = () => {
		this.$el.addClass(this.options.classes.isOpen);
	}

	closeSearch = () => {
		this.$el.removeClass(this.options.classes.isOpen)
	}

  onClickBg = (e, target) => {
      this.closeSearch();
  }

	onKeyUp = (e) => {
    console.log(e);
		let val = $(e.target).val();
		if (val.length > 2) {
			$(e.target).closest(".c-form--search").find(".search__submit").addClass("is-active");
      this.submit = true;
    } else {
      $(e.target).closest(".c-form--search").find(".search__submit").removeClass("is-active");
      this.submit = false;
    }
	}

  onClickLens = (e) => {
    e.preventDefault();
    if(this.submit) {
      $(this.options.selectors.form).submit();
    }
  }


	// Global Handlers
	get subscribe() {
		return {
			'{{context.EVENTS.search.toggle}}': 'openSearch'
		};
	}

	/**
	 * Constructor for our class
	 *
	 * @see module.js
	 *
	 * @param {Object} obj - Object which is passed to our class
	 * @param {Object} obj.el - element which will be saved in this.el
	 * @param {Object} obj.options - options which will be passed in as JSON object
	 */
	constructor(obj) {
		let options = {
			selectors: {
        bg:".search__bg",
        closeEl: ".search__close",
        submitSearch: ".search__submit",
        controlEl: ".control__control",
        lens: ".c-cta--search",
        form: ".c-form--search"
      },
			classes: {
				isOpen: "is-open"
			}
		};

		super(obj, options);
	}

	didMount() {
		console.log('Component Search mounted!');
    this.submit = false;
	}

	/**
	 * Render class
	 */
	render() {
		return this;
	}
}

export default Search;
