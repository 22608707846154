/**
 * Description of Control.
 * Class properties and decorators are supported.
 *
 * @module Control
 * @version v0.0.0
 *
 * @author your_name
 */

// Imports
import $ from 'jquery';
import Component from '@veams/component';

import AirDatepicker from 'air-datepicker';
import AirDatepickerDe from "air-datepicker/src/js/i18n/datepicker.de.js";
import AirDatepickerFr from "air-datepicker/src/js/i18n/datepicker.fr.js";
import AirDatepickerEn from "air-datepicker/src/js/i18n/datepicker.en.js";

class Control extends Component {
	/**
	 * Class Properties
	 */
	$el = $(this.el);
	$control = this.$el.find(".control__control");

	/**
	 * Constructor for our class
	 *
	 * @see module.js
	 *
	 * @param {Object} obj - Object which is passed to our class
	 * @param {Object} obj.el - element which will be saved in this.el
	 * @param {Object} obj.options - options which will be passed in as JSON object
	 */
	constructor(obj) {
		let options = {
			initSelectedClass: "init-selected",
			selectors: {
				nativeEl: ".control__control"
			}
		};

		super(obj, options);
	}

	/**
	 * Get module information
	 */
	static get info() {
		return {
			version: '0.0.0'
		};
	}

	/**
	 * Get and set the selected state.
	 *
	 * @param {boolean} state - selected state
	 */
	get selected() {
		return this._selected;
	}

	set selected(state) {
		this._selected = state;
	}


	/**
	 * Event handling
	 */
	get events() {
		let events = {};
		// FOR CUSTOM DROPDOWN ONLY
		if (this.options.customDropdown) {
			events['click {{this.options.toggler}}'] = 'onClickToggler';
			events['change {{this.options.select}}'] = 'onChange';
			events['click {{this.options.linkClass}}'] = 'onClickLink';
		}
		if (this.options.isCheckType) {
			events['change input'] = 'toggleChecked';
		}
		// BUGGY IN VEAMS, EVENTS FOCUS AND BLUR NOT REGISTERED
		//events['focus {{this.options.selectors.nativeEl}}'] = "onFocus";
		//events['blur {{this.options.selectors.nativeEl}}'] = "onBlur";
		return events;
	}

	bindEvents() {
		this.$el.find(this.options.selectors.nativeEl).on("focus", this.onFocus.bind(this));
		this.$el.find(this.options.selectors.nativeEl).on("blur", this.onBlur.bind(this));
	}

	/**
	 * Subscribe handling
	 */
	get subscribe() {
		let events = {};
		if (this.options.isDatepicker) {
			events['{{Veams.EVENTS.control.controlDate}}'] = 'checkDate';
		}
		// FOR CUSTOM DROPDOWN ONLY
		if (this.options.customDropdown) {
			events['{{Veams.EVENTS.resize}}'] = 'setSelectedOption';
		}

		return events;

	}

	toggleChecked(e) {
		let $controlGroup = this.$control.closest(".form__control-group");
		let $controlsInGroup = $controlGroup.find(".control--checkbox, .control--radio, .control--tag");
		for (let i = 0; i < $controlsInGroup.length; i++) {
			let $control = $($controlsInGroup[i]);
			let $controlInput = $control.find("input");
			if ($controlInput.is(":checked")) {
				$control.addClass("is-checked");
			} else {
				$control.removeClass("is-checked");
			}
		}
	}

	/**
	 * Initialize the view
	 *
	 */
	didMount() {
		console.log('init Control');
		let component = this;

		this.bindEvent();

		if (this.options.isCheckType) {
			this.toggleChecked();
		}

		if (this.options.customDropdown) {
			this.createCustomDropdown();
			this.$toggler = this.$el.find(this.options.toggler);
			this.setSelectedOption();
		}

		if (this.options.isDatepicker) {
			this.initAirDatepicker();
		}

		//initialize dirt
		this.onBlur();
	}

	onFocus() {
		this.$el.addClass("focus");
	}

	onBlur() {
		this.$el.removeClass("focus");
		if (this.$control.val().length) {
			this.$el.addClass("dirty");
		} else {
			this.$el.removeClass("dirty");
		}
	}


	initAirDatepicker() {
		let component = this;
		this.$control.keypress(function (e) {
			return false
		});


		// Create start date
		var start = new Date(),
			prevDay,
			startHours = 9;

		// 09:00 AM
		start.setHours(9);
		start.setMinutes(0);

		// If today is Saturday or Sunday set 10:00 AM
		if ([6, 0].indexOf(start.getDay()) != -1) {
			start.setHours(10);
			startHours = 10
		}
		//var disabledDays = [0, 6];
		this.$control.datepicker({
			inline: false,
			language: component.options.language,
			startDate: new Date(),
			minDate: new Date(),
			maxDate: new Date(new Date().setFullYear(new Date().getFullYear() + 2)),
			multipleDates: false,
			onSelect: function (fd, date, inst) {
				let selectedDate = date;
				let calendarName = component.$control.attr("name");
				Veams.Vent.trigger(Veams.EVENTS.control.controlDate, {
					date: selectedDate,
					name: calendarName,
					component: component
				});
			}
		});
	}

	checkDate = (obj) => {
		if (obj.name == "in") {
			let $outDateControl = $(".control__control[name='out']");
			let inDate = obj.date;
			let inDateSelected = new Date(inDate);
			let outDayMin = new Date(inDate);
			outDayMin.setDate(inDateSelected.getDate() + 1);
			$outDateControl.datepicker({
				inline: false,
				language: obj.component.options.language,
				startDate: new Date(),
				minDate: outDayMin,
				maxDate: new Date(new Date().setFullYear(new Date().getFullYear() + 2)),
				multipleDates: false
			});
		} else if (obj.name == "out") {
			let $inDateControl = $(".control__control[name='in']");
			let outDate = obj.date;
			let outDateSelected = new Date(outDate);
			let inDayMax = new Date(outDate);
			inDayMax.setDate(outDateSelected.getDate() - 1);
			$inDateControl.datepicker({
				inline: false,
				language: obj.component.options.language,
				startDate: new Date(),
				maxDate: inDayMax,
				multipleDates: false
			});
		}
	}

	// FOR CUSTOM DROPDOWN ONLY
	createCustomDropdown() {
		let $options = this.$control.find("option");
		let markup = '<button class="' + this.options.toggler.split(".")[1] + '" type="button" name="button">' + $($options[0]).text() + '</button>'
		markup += '<ul class="dropdown__list">';
		$.each($options, function (i, val) {
			//hide first (default)option
			/*
			 if(i == 0){
			 return true;
			 }
			 */
			let value = $(val).val();
			let text = $(val).text();
			markup += '<li class="dropdown__list-item">';
			markup += '<a class="dropdown__link" href="#" data-val="' + value + '">' + text + '</a>';
			markup += '</li>'
		});
		markup += "</ul>";
		let $markup = $(markup);
		$markup.insertBefore(this.$control);
	}

	// FOR CUSTOM DROPDOWN ONLY
	setSelectedOption(e) {
		let component = this;
		let $selectedOption = this.$control.find(":selected");
		let val = $selectedOption.val();
		let text = $selectedOption.text();
		this.$toggler.text(text);
		this.updateSelectedClass(val);
		if (e != undefined) {
			this.triggerEvent(e);
		}
	}

	// FOR CUSTOM DROPDOWN ONLY
	onClickToggler(e) {
		e.preventDefault();
		this.toggleDropdown();
	}

	// FOR CUSTOM DROPDOWN ONLY
	toggleDropdown() {
		let component = this;
		if (this.active) {
			this.closeDropdown();
		} else {
			if (this.options.isSelectPhoenix) {
				this.$select.click();
			} else {
				if (Veams.detections.width < 768) {
					this.$select.click();
				} else {
					this.openDropDown();
					setTimeout(function () {
						$('html').one('click', function () {
							component.closeDropdown();
						});
					}, 1);
				}
			}
		}
	}

	// FOR CUSTOM DROPDOWN ONLY
	openDropDown() {
		let component = this;
		this.active = true;
		this.$el.addClass(this.options.activeClass);
	}

	// FOR CUSTOM DROPDOWN ONLY
	closeDropdown() {
		this.active = false;
		this.$el.removeClass(this.options.activeClass);
	}

	// FOR CUSTOM DROPDOWN ONLY
	onClickLink(e) {
		let component = this;
		e.preventDefault();
		this.$el.toggleClass(this.options.activeClass);
		let $link = $(e.target);
		let val = $link.data("val");
		let text = $link.text();
		let $option = this.$control.find("option[value='" + val + "']");
		if ($option.length) {
			$option = this.$control.find('option[value]:first')
		}
		this.$control.val(val);
		this.$control.trigger("change");
		this.$toggler.text(text);
		this.updateSelectedClass(val);
		this.triggerEvent(e);

		Veams.Vent.trigger(Veams.EVENTS.control.selectChange, {
			$el: component.$el,
			$control: component.$control
		});
	}

	// FOR CUSTOM DROPDOWN ONLY
	updateSelectedClass(val) {
		if (this.options.selectedClass) {
			this.$el.addClass(this.options.initSelectedClass);
			if (val !== "") {
				this.selected = true;
				this.$el.addClass(this.options.selectedClass);
			} else {
				this.selected = false;
				this.$el.removeClass(this.options.selectedClass);
			}
		}
	}

	// FOR CUSTOM DROPDOWN ONLY
	onChange(e) {
		let component = this;
		this.setSelectedOption(e);
		Veams.Vent.trigger(Veams.EVENTS.selectChange, {
			$el: component.$el,
			$control: component.$control
		});
	}

	bindEvent() {
		let component = this;
		if (this.options.triggerEvent) {
			let handler = this.options.triggerEvent.split(":")[1];
			let fnTriggerEvent = component.triggerEvent.bind(this);
			this.$control.on(handler, fnTriggerEvent);
		}
	}

	triggerEvent(e) {
		let component = this;
		Veams.Vent.trigger(component.options.triggerEvent, {
			$el: component.$el,
			$control: component.$control
		});
	}


	/**
	 * Render class
	 */
	render() {
		return this;
	}
}

export default Control;
