/**
 * Description of Overlay.
 * Class properties and decorators are supported.
 *
 * @module Overlay
 * @version v0.0.0
 *
 * @author your_name
 */

// Imports
import $ from 'jquery';
import Component from '@veams/component';

class Overlay extends Component {
	/**
	* Class Properties
	*/
	$el = $(this.el);
  $bg = this.$el.find(this.options.selectors.bg);

	/**
	 * Constructor for our class
	 *
	 * @see module.js
	 *
	 * @param {Object} obj - Object which is passed to our class
	 * @param {Object} obj.el - element which will be saved in this.el
	 * @param {Object} obj.options - options which will be passed in as JSON object
	 */
	constructor(obj) {
		let options = {
			selectors: {
        iframe: ".overlay__iframe",
        wrapper: ".overlay__wrapper",
        bg: ".overlay__bg"
      },
			classes: {
        isOpen: "is-open"
      }
		};

		super(obj, options);
	}

  // Global Handlers
  get subscribe() {
    return {
      '{{context.EVENTS.navInteractive.bookatable}}': 'openOverlay',
      '{{context.EVENTS.youtube.play}}': 'openYoutubeOverlay',
      'click {{this.options.selectors.bg}}': 'onClickBg'
    };
  }

  onClickBg = (e, target) => {
      this.closeOverlay();
  }

  openOverlay = (param) => {
    this.$el.addClass(this.options.classes.isOpen);
    this.$el.find(this.options.selectors.iframe).attr("src", param.el);
  }

  openYoutubeOverlay = (param) => {
    console.log(param);
    this.$el.addClass(this.options.classes.isOpen);
    this.$el.find(".overlay__content").html(param.el);
  }

  closeOverlay = () => {
    this.$el.removeClass(this.options.classes.isOpen);
    this.$el.find(".overlay__content").html(this.iframeHtml);
  }

  handleClick = (evt, target) => {
		console.log(target);
	};

	didMount() {
		console.log('Component Overlay mounted!');
    this.iframeHtml = this.$el.find(".overlay__content").html();
	}

	/**
	 * Render class
	 */
	render() {
		return this;
	}
}

export default Overlay;
