/**
 * Description of Form.
 * Class properties and decorators are supported.
 *
 * @module Form
 * @version v0.0.0
 *
 * @author your_name
 */

// Imports
import $ from 'jquery';
import Component from '@veams/component';

const jqueryval = require("jquery-validation");

class Form extends Component {
	/**
	 * Class Properties
	 */
	$el = $(this.el);
	condition1El = this.el.querySelector(this.options.selectors.condition1) || false;

	/**
	 * Constructor for our class
	 *
	 * @see module.js
	 *
	 * @param {Object} obj - Object which is passed to our class
	 * @param {Object} obj.el - element which will be saved in this.el
	 * @param {Object} obj.options - options which will be passed in as JSON object
	 */
	constructor(obj) {
		let options = {
			selectors: {
				headerForm: ".form--header",
				headerFormLink: ".c-cta--default",
				headerFormInputDate: ".control--date",
				headerFormDropdown: ".control--custom-dropdown",
				control: ".control__control",
				selectCountryPhoenix: '[data-js-item="phoenix-country-1"]',
				condition1: '.condition-1'
			},
			classes: {
				headerForm: "form--header",
				valid: "input-validation-valid"
			}
		};

		super(obj, options);
	}

	/**
	 * Get module information
	 */
	static get info() {
		return {
			version: '0.0.0'
		};
	}

	/**
	 * Event handling
	 */
	get events() {
		return {
			//'click {{this.options.selectors.headerFormLink}}': 'onLinkFormHeaderClick'
			'change {{this.options.selectors.selectCountryPhoenix}}': 'checkSwitzerland'
		}
	}

	checkSwitzerland = (e, el) => {
		if (el.value === 'CH' && this.condition1El) {
			this.condition1El.style.display = 'block';
		} else {
			this.condition1El.style.display = 'none';
		}
	};

	/**
	 * Subscribe handling
	 */
	get subscribe() {
		let events = {
			'{{Veams.EVENTS.control.selectChange}}': 'onSelectChange'
		}
		//if(this.options.subscribeEvent){
		//events[this.options.subscribeEvent] = 'onTest'
		//}
		return events;
	}

	//onTest(){

	//}
	/**
	 * Initialize the view
	 *
	 */
	didMount() {
		console.log('init Form');
		this.addValidationMethods();
		this.validate();
	}

	addValueHref = () => {

	}

	onLinkFormHeaderClick = (e) => {
		let component = this;
		let formUrl = $(this.$el).data("form-url");
		let $inputDate = this.$el.find(this.options.selectors.headerFormInputDate);
		let $dropdown = this.$el.find(this.options.selectors.headerFormDropdown);
		for (let i = 0; i <= $inputDate.length; i++) {
			if ($($inputDate[0]).find(this.options.selectors.control).hasClass(this.options.classes.valid)
				&& $($inputDate[1]).find(this.options.selectors.control).hasClass(this.options.classes.valid)
				&& $dropdown.hasClass("selected")
			) {
				e.preventDefault();

				let dropdownValue = $dropdown.find(this.options.selectors.control).val();
				formUrl += "q&guests=";
				formUrl += dropdownValue;

				let inputValue1 = $($inputDate[0]).find(this.options.selectors.control).val();
				let day1 = inputValue1.substring(0, 2);
				let month1 = inputValue1.substring(3, 5);
				let year1 = inputValue1.substring(6, 10);

				formUrl += "&in=";
				formUrl += year1 + "-";
				formUrl += month1 + "-";
				formUrl += day1;

				let inputValue2 = $($inputDate[1]).find(this.options.selectors.control).val();
				let day2 = inputValue2.substring(0, 2);
				let month2 = inputValue2.substring(3, 5);
				let year2 = inputValue2.substring(6, 10);

				formUrl += "&out=";
				formUrl += year2 + "-";
				formUrl += month2 + "-";
				formUrl += day2;

				$(component.options.selectors.headerFormLink).attr("href", formUrl);
				window.open(formUrl, '_blank');
				$(component.options.selectors.headerFormLink).attr("href", "");
			} else {
				break;
			}
		}
	}

	addValidationMethods() {
		$.validator.addMethod("pattern", function (value, element, param) {
			if (this.optional(element)) {
				return true;
			}
			if (typeof param === "string") {
				param = new RegExp("^(?:" + param + ")$");
			}
			return param.test(value);
		}, "Invalid format.");
		$.validator.addMethod(
			"date",
			function (value, element) {
				return value.match(/^\d\d?\.\d\d?\.\d\d\d\d$/) || value.match(/^\d\d?\/\d\d?\/\d\d\d\d$/);
			},
			"Please enter a date in the format dd/mm/yyyy."
		);
		$.validator.addMethod(
			"minage",
			function (value, element) {
				let splitChar = ".";
				if (value.indexOf(".") < 0) {
					splitChar = "/";
				}
				let dateArray = value.split(splitChar);
				let day = dateArray[0];
				let month = dateArray[1];
				let year = dateArray[2];
				let birthday = new Date(year, month - 1, day);
				let ageDifMs = Date.now() - birthday.getTime();
				let ageDate = new Date(ageDifMs); // miliseconds from epoch
				let age = ageDate.getUTCFullYear() - 1970;
				return age > 17;
			},
			"Please enter a date in the format dd/mm/yyyy."
		);
		$.validator.addMethod("requirefromgroup", function (value, element, options) {
			if (typeof options == "string") {
				options = options.replace("[", "").replace("]", "").split(",");
			}
			var $fields = $(options[1], element.form),
				$fieldsFirst = $fields.eq(0),
				validator = $fieldsFirst.data("valid_req_grp") ? $fieldsFirst.data("valid_req_grp") : $.extend({}, this),
				isValid = $fields.filter(function () {
					return validator.elementValue(this);
				}).length >= options[0];

			// Store the cloned validator for future validation
			$fieldsFirst.data("valid_req_grp", validator);

			// If element isn't being validated, run each require_from_group field's validation rules
			if (!$(element).data("being_validated")) {
				$fields.data("being_validated", true);
				$fields.each(function () {
					validator.element(this);
				});
				$fields.data("being_validated", false);
			}
			return isValid;
		}, $.validator.format("Please fill at least {0} of these fields."));
	}

	onSelectChange(e) {
		if (this.$el.find(e.$control).length) {
			e.$control.valid();
		}
	}

	validate() {
		let comp = this;
		let formErrorClass = ".form__error";
		let validationErrorClass = "input-validation-error";
		let validationSuccessClass = "input-validation-valid";
		let wrapperValidationErrorClass = "form-validation-error";
		let wrapperValidationSuccessClass = "form-validation-valid";
		let inputWrapper = ".form__control-group";
		$(this.$el).validate({
			debug: false,
			highlight: function (element) {
				$(element).closest(inputWrapper).find(formErrorClass).addClass(wrapperValidationErrorClass).removeClass(wrapperValidationSuccessClass);
				$(element).closest(inputWrapper).addClass(wrapperValidationErrorClass).removeClass(wrapperValidationSuccessClass);
				$(element).addClass(validationErrorClass).removeClass(validationSuccessClass);
			},
			unhighlight: function (element) {
				$(element).closest(inputWrapper).find(formErrorClass).removeClass(wrapperValidationErrorClass).addClass(wrapperValidationSuccessClass);
				$(element).closest(inputWrapper).removeClass(wrapperValidationErrorClass).addClass(wrapperValidationSuccessClass);
				$(element).addClass(validationSuccessClass).removeClass(validationErrorClass);
			},
			success: function (element) {
				$(element).closest(inputWrapper).find(formErrorClass).removeClass(wrapperValidationErrorClass).addClass(wrapperValidationSuccessClass);
				$(element).closest(inputWrapper).removeClass(wrapperValidationErrorClass).addClass(wrapperValidationSuccessClass);
				$(element).removeClass(validationErrorClass).addClass(validationSuccessClass);
			},
			errorPlacement: function (error, element) {
				let text = error.text();
				let $element = element.closest(inputWrapper).find(formErrorClass);
				$element.html(error.text());
			},
			submitHandler: function (form, e) {
				if ($(form).valid()) {
					if (comp.options.isPhoenixForm) {
						Veams.Vent.trigger(Veams.EVENTS.form.phoenix, comp.options.phoenixControlsJsItem)
					} else {
						form.submit();
					}
				} else {
					return false;
				}
			}
		});
	}

	/**
	 * Render class
	 */
	render() {
		return this;
	}
}

export default Form;
