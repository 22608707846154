/**
 * Description of Toggler.
 * Class properties and decorators are supported.
 *
 * @module Toggler
 * @version v0.0.0
 *
 * @author your_name
 */

// Imports
import $ from '@veams/query';
import Component from '@veams/component';

class Toggler extends Component {
	/**
	* Class Properties
	*/

    /**
     * Event & subscribe handling
     */

	// Local Handlers
    get events() {
        return {
            'click': 'handleClick'
        };
    }

    get isActive() {
      return this._isActive || false;
    }

    set isActive(active) {
      this._isActive = active;
    }

    toggleClass = () => {
      if(this.isActive){
        this.removeActiveClass();
      }
      else{
        this.addActiveClass();
      }
    };

    addActiveClass = () => {
      $(this.el).addClass(this.options.classes.isActive);
      this.isActive = true;
    };

    removeActiveClass = () => {
      $(this.el).removeClass(this.options.classes.isActive);
      this.isActive = false;
    };



	handleClick = (evt, target) => {
        console.log(`You clicked on: `, target);
        console.log('You got the following event: ', evt);
        Veams.Vent.trigger(Veams.EVENTS.navMain.toggle);
        Veams.Vent.trigger(Veams.EVENTS.searchIcon.toggle);
	};

  // Global Handlers
  get subscribe() {
    return {
      '{{context.EVENTS.navMain.toggle}}': 'toggleClass'
    };
  }

	/**
	 * Constructor for our class
	 *
	 * @see module.js
	 *
	 * @param {Object} obj - Object which is passed to our class
	 * @param {Object} obj.el - element which will be saved in this.el
	 * @param {Object} obj.options - options which will be passed in as JSON object
	 */
	constructor(obj) {
		let options = {
			selectors: {},
			classes: {
        isActive: "is-active"
      }
		};


		super(obj, options);
	}

	didMount() {
		console.log('Component Toggler mounted!');
	}

	/**
	 * Render class
	 */
	render() {
		return this;
	}
}

export default Toggler;
