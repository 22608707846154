/**
 * Const for events (pub/sub)
 */

/**
 * Events Global
 */
const EVENTS = {};

/**
 * Events for window
 */
EVENTS.youtubeApiReady = 'youtubeApiReady';

/**
 * Events for Youtube
 */
EVENTS.youtube = {
	play: 'youtube:play'
};

/**
 * Events for NavMain
 */
EVENTS.navMain = {
	toggle: 'navMain:toggle'
};

/**
 * Events for SearchIcon
 */
EVENTS.searchIcon = {
	toggle: 'search-icon:toggle'
};

/**
 * Events for Search
 */
EVENTS.search = {
	toggle: 'search:toggle'
};

/**
 * Events for NavInteractive
 */
EVENTS.navInteractive = {
	toggle: 'navInteractive:toggle',
	bookatable: 'navInteractive:bookatable'
};

/**
 * Events for Form
 */
EVENTS.form = {
	phoenix: 'form:phoenixSubmit'
};

/**
 * Events for Control
 */
EVENTS.control = {
  selectChange:"select:change",
	controlDate:"input:change"
};


/**
 * Events for PhoenixPanel
 */
EVENTS.phoenixPanel = {
	eventName: 'phoenixPanel:eventName'
};
// @INSERTPOINT :: @ref: js-events

export default EVENTS;
