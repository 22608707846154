/**
 * Description of Gallery.
 * Class properties and decorators are supported.
 *
 * @module Gallery
 * @version v0.0.0
 *
 * @author Noémie Viatte
 */

// Imports
import $ from 'jquery';
import Component from '@veams/component';
require('slick-carousel');

class Gallery extends Component {
	/**
	 * Class Properties
	 */
	$el = $(this.el);

	/**
	 * Event & subscribe handling
	 */

	// Local Handlers
	get events() {
		return {
			'click {{this.options.selectors.slide}}': 'onThumbnailClick'
		};
	}

	handleClick = (evt, target) => {
		console.log(`You clicked on: `, target);
		console.log('You got the following event: ', evt);
	};

	onThumbnailClick = (e) => {
		e.preventDefault();
	}



	/**
	 * Constructor for our class
	 *
	 * @see module.js
	 *
	 * @param {Object} obj - Object which is passed to our class
	 * @param {Object} obj.el - element which will be saved in this.el
	 * @param {Object} obj.options - options which will be passed in as JSON object
	 */
	constructor(obj) {
		let options = {
			selectors: {
				gallery: ".gallery__slick",
				nav: ".gallery__nav",
				slide: ".gallery__slide"
			},
			classes: {

			}
		};

		super(obj, options);
	}

	didMount() {
		console.log('Component Gallery mounted!');
		let $gallerySlick = this.$el.find(this.options.selectors.gallery);
		let $galleryNav = this.$el.find(this.options.selectors.nav);
    let component = this;
    component.initSlider($gallerySlick, $galleryNav);
	}

  initSlider($gallery, $nav) {
    $gallery.slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      fade: false,
      asNavFor: this.$el.find(this.options.selectors.nav),
      rows: 0,
      nextArrow: this.$el.find(".gallery__arrow.next"),
      prevArrow: this.$el.find(".gallery__arrow.prev")
    });
    $nav.slick({
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      asNavFor: this.$el.find(this.options.selectors.gallery),
      dots: true,
      focusOnSelect: true,
      arrows: false,
      rows: 0,
      //centerMode: true,
      //centerPadding: '0px',
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        }
      ]
    });
    //$nav.slick("slickGoTo", "2");
  }

	/**
	 * Render class
	 */
	render() {
		return this;
	}
}

export default Gallery;
