/**
 * Main entry file for styles.
 */
import './app.scss';
/**
 * Main entry file for application.
 */

import {Veams} from './app.veams';
import Gallery from './shared/components/gallery/scripts/gallery';
import Header from './shared/components/header/scripts/header';
import Toggler from './shared/components/toggler/scripts/toggler';
import NavMain from './shared/components/nav-main/scripts/nav-main';
import Search from './shared/components/search/scripts/search';
import SearchIcon from './shared/components/search/scripts/search-icon';
import Cta from './shared/components/cta/scripts/cta';
import NavInteractive from './shared/components/nav-interactive/scripts/nav-interactive';
import TList from './shared/components/teaser/scripts/t-list';
import Control from './shared/components/control/scripts/control';
import Form from './shared/components/form/scripts/form';
import Overlay from './shared/components/overlay/scripts/overlay';
import Youtube from './shared/components/youtube/scripts/youtube';
import Cookiedisclaimer from "./shared/components/cookiedisclaimer/scripts/cookiedisclaimer";
import PhoenixPanel from "./shared/components/phoenix-panel/scripts/phoenix-panel";
import AdhocArchive from "./shared/components/adhoc-archive/scripts/adhoc-archive";
// Init Gallery

Veams.modules.add({
	namespace: 'gallery',
	module: Gallery
}); // Init Overlay

Veams.modules.add({
	namespace: 'overlay',
	module: Overlay
}); // Init Header

Veams.modules.add({
	namespace: 'header',
	module: Header
}); // Init Youtube

Veams.modules.add({
	namespace: 'youtube',
	module: Youtube
}); // Init Cookiedisclaimer

Veams.modules.add({
	namespace: 'cookiedisclaimer',
	module: Cookiedisclaimer
}); // Init Form

Veams.modules.add({
	namespace: 'form',
	module: Form
}); // Init Control

Veams.modules.add({
	namespace: 'control',
	module: Control
}); // Init TList

Veams.modules.add({
	namespace: 't-list',
	module: TList
}); // Init NavInteractive

Veams.modules.add({
	namespace: 'nav-interactive',
	module: NavInteractive
}); // Init Cta

Veams.modules.add({
	namespace: 'cta',
	module: Cta
}); // Init SearchIcon

Veams.modules.add({
	namespace: 'search-icon',
	module: SearchIcon
}); // Init Search

Veams.modules.add({
	namespace: 'search',
	module: Search
}); // Init Toggler

Veams.modules.add({
	namespace: 'toggler',
	module: Toggler
}); // Init NavMain

Veams.modules.add({
	namespace: 'nav-main',
	module: NavMain
});

Veams.modules.add({
	namespace: 'adhoc-archive',
	module: AdhocArchive
});

Veams.modules.add({namespace: 'phoenix-panel', module: PhoenixPanel});
// @INSERTPOINT :: @ref: js-init-modules-@1, @keep: true //
// @INSERTPOINT :: @ref: js-init-once, @keep: true //
