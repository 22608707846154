/**
 * Description of Search.
 * Class properties and decorators are supported.
 *
 * @module Search
 * @version v0.0.0
 *
 * @author your_name
 */

// Imports
import $ from 'jquery';
import Component from '@veams/component';

class SearchIcon extends Component {
	/**
	* Class Properties
	*/
	$el = $(this.el);

  /**
   * Event & subscribe handling
   */

  // Local Handlers
  get events() {
    return {
      'click': 'handleClick'
    };
  }

  get isActive() {
    return this._isActive || false;
  }

  set isActive(active) {
    this._isActive = active;
  }

  handleClick = (evt, target) => {
		console.log(`You clicked on: `, target);
		console.log('You got the following event: ', evt);
    Veams.Vent.trigger(Veams.EVENTS.search.toggle);
	};

  handleResize = () => {
    console.log(`Browser was resized and caught by Search!`);
    this.hideSearchIcon();
  };

  toggleSearchIcon = () => {
    if(this.isActive){
      this.hideSearchIcon();
    }
    else{
      this.showSearchIcon();
    }
	};

  showSearchIcon  = () => {
    this.$el.addClass(this.options.classes.isVisible);
    this.isActive = true;
  };

  hideSearchIcon  = () => {
    this.$el.removeClass(this.options.classes.isVisible);
    this.isActive = false;
  };

  // Global Handlers
  get subscribe() {
    return {
      //'{{context.EVENTS.resize}}': 'handleResize',
      '{{context.EVENTS.searchIcon.toggle}}': 'toggleSearchIcon',
      '{{context.EVENTS.resize}}': 'handleResize',
    };
  }


  /**
	 * Constructor for our class
	 *
	 * @see module.js
	 *
	 * @param {Object} obj - Object which is passed to our class
	 * @param {Object} obj.el - element which will be saved in this.el
	 * @param {Object} obj.options - options which will be passed in as JSON object
	 */
	constructor(obj) {
		let options = {
			selectors: {},
			classes: {
        isVisible: "is-visible"
      }
		};

		super(obj, options);
	}

	didMount() {
		console.log('Component SearchIcon mounted!');
	}

	/**
	 * Render class
	 */
	render() {
		return this;
	}
}

export default SearchIcon;
