/**
 * Description of NavInteractive.
 * Class properties and decorators are supported.
 *
 * @module NavInteractive
 * @version v0.0.0
 *
 * @author your_name
 */

// Imports
import $ from 'jquery';
import Component from '@veams/component';

class NavInteractive extends Component {
	/**
	 * Class Properties
	 */
	$el = $(this.el);

	/**
	 * Event & subscribe handling
	 */

	// Local Handlers
	get events() {
		return {
			'click {{this.options.selectors.bookTableLink}}': 'onBookingClick'
		};
	}

	get isActive() {
		return this._isActive || false;
	}

	set isActive(active) {
		this._isActive = active;
	}

	showMobileNav = () => {
		this.$el.addClass(this.options.classes.isActive);
		this.isActive = true;
	};

	hideMobileNav = () => {
		this.$el.removeClass(this.options.classes.isActive);
		this.isActive = false;
	};

	toggleNav = () => {
		if (this.isActive) {
			this.hideMobileNav();
		} else {
			this.showMobileNav();
		}
	}

	onBookingClick = (e) => {
		e.preventDefault();
		this.hideMobileNav();
		let href = e.srcElement.href;
		Veams.Vent.trigger(Veams.EVENTS.navInteractive.bookatable, {
			el: href
		});
	}

	// Global Handlers
	get subscribe() {
		return {
			'{{context.EVENTS.navInteractive.toggle}}': 'toggleNav',
			'{{context.EVENTS.navMain.toggle}}': 'hideMobileNav'
		};
	}

	handleResize = () => {
		console.log(`Browser was resized and caught by NavInteractive!`);
	};

	/**
	 * Constructor for our class
	 *
	 * @see module.js
	 *
	 * @param {Object} obj - Object which is passed to our class
	 * @param {Object} obj.el - element which will be saved in this.el
	 * @param {Object} obj.options - options which will be passed in as JSON object
	 */
	constructor(obj) {
		let options = {
			selectors: {
				bookTableLink: ".bookatable"
			},
			classes: {
				isActive: "is-active"
			}
		};

		super(obj, options);
	}

	didMount() {
		console.log('Component NavInteractive mounted!');
		this.loadBookATable();
	}

	loadBookATable() {
		async ("https://bda.bookatable.com/deploy/lbui.direct.min.js", function() {

			try {
				if (typeof window.embedTableReservationTool === "function") {
					window.embedTableReservationTool();
				}
				$("#reserve-table").lbuiDirect({
					connectionid: "CH-RES-MERIDIANO:75218",
					style: {
						useFlatDesign: false
					},
					language: "de-CH",
					hideLanguageOptions: true,
					gaAccountNumber: "UA-5284541-1",
					modalWindow: {
						enabled: true
					}
				});
			} catch (e) {
        if($("#reserve-table").length){
          $("#reserve-table").parent("li").remove();
        }
				console.log(e);
			}
		});
	}

	/**
	 * Render class
	 */
	render() {
		return this;
	}
}

export default NavInteractive;
