/**
 * Description of Header.
 * Class properties and decorators are supported.
 *
 * @module Header
 * @version v0.0.0
 *
 * @author Noémie Viatte
 */

// Imports
import $ from 'jquery';
import Component from '@veams/component';

class Header extends Component {
	/**
	 * Class Properties
	 */
	$el = $(this.el);

	/**
	 * Event & subscribe handling
	 */

	// Local Handlers
	get events() {
		return {

		};
	}


	// Global Handlers
	get subscribe() {
		return {
			'{{Veams.EVENTS.scroll}}': 'onScroll',
      '{{Veams.EVENTS.resize}}': 'onScroll'
		};
	}

	onScroll = (e) => {
    let $rHeader = $(".r-top-wrapper");
    if(Veams.detections.width < 1200) {
      let height = $(window).scrollTop();
      if (height > 50) {
        $rHeader.addClass(this.options.classes.scroll);
      } else {
        $rHeader.removeClass(this.options.classes.scroll);
      }
    } else {
      $rHeader.removeClass(this.options.classes.scroll);
    }

	}

  headerAnimation = () => {
    let component = this;
    setTimeout(function() {
      component.$el.find(".header__content").addClass("animated");
    }, 600);
  }

	/**
	 * Constructor for our class
	 *
	 * @see module.js
	 *
	 * @param {Object} obj - Object which is passed to our class
	 * @param {Object} obj.el - element which will be saved in this.el
	 * @param {Object} obj.options - options which will be passed in as JSON object
	 */
	constructor(obj) {
		let options = {
			selectors: {},
			classes: {
				isActive: "is-active",
        scroll: "scroll"
			}
		};

		super(obj, options);
	}

	didMount() {
		console.log('Component Header mounted!');
    this.onScroll();
		let $main = $(".r-main");
		let $rHeader = $(".r-header");
		if (this.$el.hasClass("c-header--detail")) {
			if (!$main.hasClass("detail")) {
				this.$el.addClass("is-hub");
			}
		}
		this.headerAnimation();
	}

	/**
	 * Render class
	 */
	render() {
		return this;
	}
}

export default Header;
